<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <v-layout wrap justify-center>
                <v-flex xs12 sm2 md1 xl2>
                  <v-avatar color="#FFF9C4" size="70">
                    <v-img
                      v-if="storage.user.photo"
                      :src="mediaURL + storage.user.photo"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FDCE48"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs12 sm10 md10 xl10 align-self-center>
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Bee Keeper</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.user.username }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Apiary Id</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.apiaryid }}
                            <span v-if="!storage.apiaryid">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Apiary Code</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.code }}
                            <span v-if="!storage.code">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Hive Count</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.colonycount }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- <v-flex xs2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Created Date</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ formatDate(storage.createddate) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                    <v-flex xs12 sm12 md4 xl8>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Location</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.locationname }}
                            <span v-if="!storage.locationname">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm12 md1 xl12 text-right align-self-center>
                  <v-btn
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Apiary/' + storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>